import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Typography,
} from "@mui/material";

interface TutorialDialogProps {
  isChecked: boolean;
  title: string;
  description: string;
  buttonText: string;
  card?: React.ReactElement;
  handleClick: (isAgree: boolean) => void;
  textAlign?: "center" | "left" | "right" | "inherit" | "justify";
}

const TutorialDialog = (props: TutorialDialogProps) => {
  const { isChecked, title, description, buttonText, card, handleClick, textAlign = "center" } = props;
  const [isAgree, setIsAgree] = useState<boolean>(false);

  return (
    <>
      <Dialog open={!isChecked}>
        <DialogTitle sx={{ textAlign: "center", color: "secondary.main", fontWeight: "bold" }}>{title}</DialogTitle>
        <DialogContent dividers={true}>
          {card ? card : <></>}
          <Typography m={2} sx={{ textAlign: textAlign, color: "secondary.main" }}>
            {description.split(/(\n)/g).map((t) => (t === "\n" ? <br /> : t))}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ flexFlow: "column" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setIsAgree(!isAgree);
                  }}
                />
              }
              label="次回から表示しない"
            />
          </FormGroup>
          <Button
            onClick={() => handleClick(isAgree)}
            sx={{
              height: "5vh",
              width: "70%",
              borderRadius: "30px",
              color: "secondary.main",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {buttonText}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TutorialDialog;
