import React from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import headerBackgroundImage from "./loginHeaderBackground.svg";
import slide2Image from "./slide2Image.svg";
import { ReactComponent as SlidePreviousButton } from "./slideLeft.svg";
import { ReactComponent as SlideNextButton } from "./slideRight.svg";
import "./Login.css";

const CONTACT_URL = process.env.REACT_APP_CONTACT_URL!;

function Login() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  async function handleLogin() {
    instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const accounts = instance.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            instance.loginRedirect().catch((e) => {
              alert("ログインが中断されました。再ロードしてからログインし直してください。");
            });
          }
        };
      })
      .catch((err) => {
        // Handle error
        console.error(err);
      });
  }

  function handleLoginQuestion() {
    window.location.href = CONTACT_URL;
  }

  function handleHomeRegistrationGuidance() {
    navigate("/guidance");
  }

  return (
    <>
      <div className="header">
        <div className="background" style={{ backgroundImage: `url(${headerBackgroundImage}` }}>
          <div className="tagline">さあ、自由に移動しよう</div>
          <div className="heading">NoluDay</div>
        </div>
      </div>
      <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={95} totalSlides={5}>
        <Slider>
          <Slide index={0}>
            <div className="loginForm">
              <div className="buttons">
                <div>
                  <button className="primary" onClick={() => handleLogin()}>
                    はじめる
                  </button>
                </div>
                <div>
                  <button className="text" onClick={() => handleLoginQuestion()}>
                    ログインに関するご質問はこちら
                  </button>
                </div>
                <div>
                  <button className="text" onClick={() => handleHomeRegistrationGuidance()}>
                    アプリをホーム画面に置く方法はこちら
                  </button>
                </div>
              </div>
            </div>
          </Slide>

          <Slide index={1}>
            <div className="slideHeader">ポイント①</div>
            <p className="slideText">
              ノルデイは簡単便利
              <br />
              <br />
              コミュニティバスや民間バス
              <br />
              たくさんある乗り物の
              <br />
              時刻検索はもちろん、
              <br />
              目的地までの最適ルートを
              <br />
              提案するよ！
            </p>
            <img src={slide2Image} alt="TODO" />
          </Slide>
          <Slide index={2}>
            <div className="slideHeader">ポイント②</div>
            <p className="slideText">
              きめこまかく移動ができる
              <br />
              デマンドバスの予約もOK
            </p>
          </Slide>
          <Slide index={3}>
            <div className="slideHeader">ポイント③</div>
            <p className="slideText">
              クレジットカードを登録すれば
              <br />
              お得なチケットも購入できるよ
            </p>
          </Slide>
          <Slide index={4}>
            <div className="slideHeader">ポイント④</div>
            <p className="slideText">
              チケットはアプリで
              <br />
              見せるだけ！
              <br />
              <br />
              現金を持ち歩かなくても
              <br />
              市内のコミュティバスや民間バスを
              <br />
              自由に利用できる
            </p>
          </Slide>
        </Slider>

        <div className="slideControls">
          <ButtonBack>
            <SlidePreviousButton />
          </ButtonBack>
          <DotGroup />
          <ButtonNext>
            <SlideNextButton />
          </ButtonNext>
        </div>

        <div className="extra">
          <p>
            ノルデイのおすすめポイントを
            <br />
            見てみよう
          </p>
        </div>
      </CarouselProvider>
    </>
  );
}

export default Login;
